.react-quiz-container{
  margin-top: 5%;
  margin-top: 5%;
  width: 100%;
  align-items: inherit;
}

#startQuizBtn{
  background-color: #59B08E;
  border: #59B08E;  
  font-size:large;
}

.Card{
  text-align: center;
}

.Card .Text{
  text-align: center;
}

.react-quiz-container .row{
  width: 100%;
}