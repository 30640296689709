.amplify-s3-image{
    height: 5px;
    width: 5px;
  }

.questionWrapperBody{
    text-align: center;
    min-height: 100%;
    margin-top: 5%;
    margin-bottom: 5%;
}


.questionWrapperBody-questions{
    background-color:white;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1); 
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 2rem;
    text-align: center;
}
.question-number{
    text-align: right;
    width: 100%;
    color: #598CAC;
    margin-right: 1rem;
  }

  .question-title{
    width: 80%;
    margin-left: 1rem;
    text-align: left;
    color: #598CAC;
    border-bottom-width: 2px ;
    border-bottom-style : solid; 
    border-bottom-color: #598CAC; 
    }

.question-question{
    text-align: left;
    width: 100%;
    margin-top: 1rem;
    margin-left: 2rem;
    font-size: large ;
}

.option-group{
    text-align: left;
    margin-top: 1rem;
    margin-left: 4rem;
    margin-bottom: 2rem;
    width: 100%;
    font-size: large ;
}

.option-checkbox{
    text-align: left;
    margin-top: 1rem;
    font-size: large;
    font-weight: 520;
    width: 100%;
    font-size: large ;
}

.btnBar .exit .exitBtn{
    background-color: #E08882;
    align-self: left;
}

.btnBar .exit .exitBtn:hover{
    background-color:#9C0018;
}

.buttonBarRow{
    width: 100%;
    
}
.btnBar{
    align-items: center;
    width: 100%;

}

.btnBar .prev-next{
    align-self: right;
}

.btnBar .prev-next .previousBtn{
    background-color: #8FC1E3;
}
.btnBar .prev-next .previousBtn:hover{
    background-color : #00334E;
}

.btnBar .prev-next .submitBtn{
    background-color: #59B08E;
}
.btnBar .prev-next .submitBtn:hover{
    background-color:#005A27;
}

