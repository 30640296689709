.pastQuizzes{
    box-shadow:  0px 10px 15px rgba(0, 0, 0, 0.1);
    background-color: white;
}

.pastQuizzes .header{
margin: 1rem;
}

.pastQuizzes h3{
 color:  #00334E;
}

.pastQuizzes .pastQuizzesTable{
    overflow-x: scroll;
}

.pastQuizzes .pastQuizzesTable .pagination>li>a{
   color: #00334E;
}

.pastQuizzes .row{
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
}

.table{
    background-color:white;
}

#closeReview {
    background-color:  #E08882;
    border-color:  #E08882;
}

#closeReview:hover{
    background-color:#9C0018;
}


