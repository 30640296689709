.container{
    min-height: 100%;
    height: 100%;
    min-height: 100vh;  
}

.faq{
    margin-top: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
}


.faqheader-top{
    width: 50%;
    margin-left: 1rem;
    margin-bottom: 1rem;
    text-align: left;
    color:#00334E;
    border-bottom-width: 2px ;
    border-bottom-style : solid; 
    border-bottom-color: #598CAC; 
}

.faqheader-mid{
    text-align: left;
    margin-left: 1rem;
    margin-top: 1rem;
}

.faqcards .Accordion{
    margin-top: 1rem
}

.faqcards .card{
    margin-top: 1rem; 
    background-color: white;
    border-bottom-color: #00334E;
    border-bottom-width: 2px ;
    border-bottom-style : solid; 
}

.faqcards  .card-header{
    background-color: #8B91C5;
    font-size: large;
}
.faqcards  .card-body{
    background-color:white; 
    font-size: medium;
}


.faqcards  .card-text{
    text-align: left;
}

/* unvisited link */
.faq a:link {
    color: #598CAC;
  }
  
  /* visited link */
  .faq a:visited {
    color: #598CAC;
  }
  
  /* mouse over link */
  .faq a:hover {
    color: #59B08E;
  }
  
  /* selected link */
  .faq a:active {
    color: #8B91C5;
  }