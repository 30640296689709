#question{
    width: 80%;
    margin-left: 1rem;
    text-align: left;
    color: #598CAC;
    border-bottom-width: 2px ;
    border-bottom-style : solid; 
    border-bottom-color: #598CAC; 
    text-decoration: none;
}

.card-title{
    text-decoration: none;
}


.card-body .question-question{
    text-align: left;
    margin-top: 1rem;
    padding-left: 2rem;
    padding-right: 1rem;
    font-size: large ;
    word-wrap: break-word;
   
}

.question-question .card-text{
    text-align: left;
    margin-top: 1rem;
    padding-left: 2rem;
    font-size: large ;
    word-wrap: break-word;
}

.card-text{
    text-align: left;
    margin-top: 1rem;
    padding-left: 2rem;
    font-size: large ;
    word-wrap: break-word;
}

.card-text{
    word-wrap: break-word;
}

.result-answer-options{
    text-align: left;
    margin-top: 1rem;
    padding-left: 4rem;
    padding-bottom: 2rem;
    width: 100%;
    font-size: large ;
}

.result-answer{
    text-align: left;
    margin-top: 1rem;
    padding-left: 4rem;
    padding-bottom: 2rem;
    width: 100%;
    font-size: large ;
}
.result-answer-header{
    width: 80%;
    padding-left: 1rem;
    text-align: left;
    color: #598CAC;
    border-bottom-width: 2px ;
    border-bottom-style : solid; 
    border-bottom-color: #598CAC; 
}

.result-platform-header{
    width: 80%;
    padding-left: 1rem;
    text-align: left;
    color: #598CAC;
    border-bottom-width: 2px ;
    border-bottom-style : solid; 
    border-bottom-color: #598CAC; 
}

.result-platform{
    text-align: left;
    margin-top: 1rem;
    padding-left: 4rem;
    padding-bottom: 2rem;
    width: 100%;
    font-size: large ;
}

.result-level-header{
    width: 80%;
    margin-left: 1rem;
    text-align: left;
    color: #598CAC;
    border-bottom-width: 2px ;
    border-bottom-style : solid; 
    border-bottom-color: #598CAC; 
}

.result-level{
    text-align: left;
    margin-top: 1rem;
    padding-left: 4rem;
    margin-bottom: 2rem;
    width: 100%;
    font-size: large ;
}

.result-services-header{
    width: 80%;
    padding-left: 1rem;
    text-align: left;
    color: #598CAC;
    border-bottom-width: 2px ;
    border-bottom-style : solid; 
    border-bottom-color: #598CAC; 
}

.result-services{
    text-align: left;
    margin-top: 1rem;
    padding-left: 4rem;
    padding-bottom: 2rem;
    padding-right: 1rem;
    font-size: large ;
    word-wrap: break-word;
}

#skip{
    margin-left: 2rem;
    margin-right: 2rem;
}

amplify-s3-image {
    --width: 100%;
    --height: 100%;
  }