.private-policy{
    text-align: left;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.private-policy h1{
    font-size: xx-large;
}

.private-policy h2{
    font-size: large;
}

.private-policy h3{
    font-size: large;
}