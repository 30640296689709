.fill{
    min-height: 100%;
    height: 100%;
    min-height: 100vh;  
}

.row{
    display: flex;
}


.HomeSignedIn{
    margin-top: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
}


#start{
    background-color:  #59B08E;
    border-color: #59B08E;
}

.card{
    font-size: large;
    width: 100%;
}
.card-title{
    color:  #00334E;
    font-weight: bold;
}

.coreCards .card-img{
    max-width: 60%;
    max-width: 60%;
}

.coreCards .card{
margin-left: 4rem;
width : 100% 
}
.card-deck{
    width: 100%;
}
.coreCards .row{
    width :100%
}

.HomeSignedIn .card-text{
    padding-left: 0;
    margin-bottom: 1rem;
}


.quesComand{
    width: 30%;
    margin-left: 1rem;
    margin-bottom: 1rem;
    text-align: left;
    color:#00334E;
    border-bottom-width: 2px ;
    border-bottom-style : solid; 
    border-bottom-color: #598CAC; 
}

@media  screen and (max-width: 600px) {
    .coreCards .card{
        margin-left: 0rem;
        width : 100% 
        }
  }
