.QuizFrame .makeQuiz{
        min-height: 100%;
        margin-top: 5%;
        margin-bottom: 5%;
}

.QuizFrame .checkSub{
        min-height: 100%;
        margin-top: 5%;
        margin-bottom: 5%;
}

.QuizFrame .card-text{
        padding-left: 0;
        margin-bottom: 1rem;
    }
    

.QuizFrame {
        min-height: 100%;
}

.card{
        padding-bottom: 2rem;
        font-weight: 500;
}

.step1 .step1-text{
        color: #00334E ;
        font-weight: 800;
        font-size: large;
}

.card-title{
        color: #00334E ;
        font-weight: bolder;
        font-size: x-large;
        text-decoration: underline;
        padding-bottom: 1rem;
}

.card-header{
        background-color :#598CAC ;
        color: white;
        font-size: x-large;
        font-weight:800 ;
}

.toast-header{
        background-color : #E08882;  
        color: white;
        font-size: large;
        font-weight: bold;
}


.toast-body{
       background-color: #00334E;
       color: white;
       font-size: large;
       text-align: center;
}

.btn{
        background-color: #59B08E;
}

#nxtBtn{
        margin-top: 1rem;
        background-color: #59B08E;
        border: #59B08E;  
        font-size:large;
}


.multiSlect{
        width: 60% ;
}

.choose{
        margin-bottom: 1rem;
}