.subQuestion{
    margin-top: 5%;
    margin-top: 5%;
}

.btn-grouo{
    display: flex;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
}

#remove{
    text-align: left;
}

#nxtBtn{
    background-color: #59B08E;
    border-color: #59B08E;
    
}

#backBtn{
    margin-top: 1rem;
    font-size: large;
    background-color: #8B91C5;
    border-color: #8B91C5;
}

#add{
    margin-right: 2rem;
    background-color : #598CAC; 
}

.option{
   padding: 1rem;
}

.divOption{
    text-align: left;
    align-items: left;
    width: 20%;
    border-bottom-width : 2px ;
    border-bottom-style : solid ; 
    border-bottom-color :  #00334E;
    margin-bottom: 1rem;
}

#remove{
    background-color: #E08882;
    border: #E08882;
    margin-top: 1rem;
}

#check{
    text-align: left;
    align-items: left;
}


.imageUpload{
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.card-text{
    margin: 0;
    text-align: center;
}