.core {
  align-items: center;
}

.react-quiz-container{
    margin-top: 5%;
    width: 60%;
    align-content: center;
  }
  
  .react-quiz-container .saveQuiz{
    margin-top: 5%;
    align-content: center;
    width: 60%;
  }