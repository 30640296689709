.footer{
    background-color: #384955;
    color: white;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
}

a{
  justify-content: center;
  align-items: center;
}
/* unvisited link */
a:link {
    color: white;
  }
  
  /* visited link */
  a:visited {
    color: white;
  }
  
  /* mouse over link */
  a:hover {
    color: #59B08E;
  }
  
  /* selected link */
  a:active {
    color: #8B91C5;
  }