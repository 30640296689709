.track .top{
    width: 50%;
    margin-left: 1rem;
    margin-bottom: 1rem;
    text-align: left;
    color:#00334E;
    border-bottom-width: 2px ;
    border-bottom-style : solid; 
    border-bottom-color: #598CAC; 
}

.fill{
    min-height: 100%;
    height: 100%;
    min-height: 100vh;  
}


.track{
    margin-top: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
}

.track .mid{
text-align: left;
margin-left: 1rem;
margin-top: 1rem;
}

.track .table{
    box-shadow:  0px 10px 15px rgba(0, 0, 0, 0.1);
    background-color: white;
}

.track  .pastQuizzesTable .pagination>li>a{
    color: #00334E;
 }