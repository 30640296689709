.results-body{
    min-height: 100vh;
    margin-top: 5%;
    margin-bottom: 5%;
}

#skip{
    margin-left: 2rem;
    margin-right: 2rem;
}

#edit{

    margin-right: 2rem;
    border: #00334E; 
    background-color: #00334E;
}


#reject{
 background-color: #E08882;
 border: #E08882;

}

#accept{
   background-color:  #59B08E;
   border: #59B08E;
}





.card-title{
    color: #00334E ;
    font-weight: bolder;
    font-size: x-large;
    text-decoration: underline;
    padding-bottom: 1rem;
}

.card-header{
    background-color :#598CAC ;
    color: white;
    font-size: x-large;
    font-weight:800 ;
}


.quiz-result-filter-form{
    align-items: center;
}

.topic{
    margin-bottom: 2rem;
}

.questionifno{
    margin-bottom: 2rem;
}

.quiz-result-filter-form{
    text-align: center;
    margin-bottom: 2rem;
}
.imagePrev{
    margin-top: 3rem;
    margin-bottom: 2rem;
    padding-top: 1rem;
    border-top: #00334E;
    border-radius: 5px;
    border-style: solid;
}

.editBtns{
    margin-top: 1rem;
}