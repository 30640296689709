.terms{
    text-align: left;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.terms h1{
    font-size: xx-large;
}

.terms h2{
    font-size: large;
}

.terms h3{
    font-size: large;
}