.container{
    min-height: 100%;
    height: 100%;
    min-height: 100vh;  
}

.contact{
    margin-top: 1rem;
    margin-left: 2rem;
    margin-right: 2rem;
}


.contactheader-top{
    width: 50%;
    margin-left: 1rem;
    margin-bottom: 1rem;
    text-align: left;
    color:#00334E;
    border-bottom-width: 2px ;
    border-bottom-style : solid; 
    border-bottom-color: #598CAC; 
}

.contactbottom{
    text-align: left;
}

.contact .form-label{
    font-weight: 600;
}

.error{
 text-align: center;
}
.error .form-label{ 
    color: #E08882
}

