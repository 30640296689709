
.container-fluid{
    padding: 0%;
    margin: 0%;
}

.homePageTop{
    background-color: #8FC1E3;
}

.homePageHeader{ 
    background-color: #8FC1E3;
    padding-top: 8rem;
}

.homePageQoute{
    padding-top: 3rem;
    font-style: italic;
    color: #384955;
    text-shadow:5px;
}

.HomepageSignUp{
    padding-top: 3rem;
    padding-bottom: 5rem;
    font-weight: 600;
}

.HomepageSignUp .btn{
    background-color: #59B08E ;
    color: #F8F8FF;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1); 
   
}

.homepageCards .row .card {
    min-height: 20rem; 
    width: 100%;
    margin-left: 4rem;
    margin-right: 4rem;
    margin-top: 4rem;
    margin-bottom: 2rem;
}

.homepageCards .row .card .card-img-top{
    max-width: 300px;
   display: inline-block;
}

.card .btn{
    background-color: #8B91C5;
    color: #F8F8FF;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
}

.homepageCards .card-text{
    padding-left: 0;
}

