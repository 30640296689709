.pastQuizes .result-answer-wrapper{
    margin-top: 1rem;
    width: 100%; 
}

.react-quiz-container { 
   margin-top: 1rem;
}

.pastQuizes  .results-body{
    margin-top: 1rem;
    
}

.react-quiz-container .results-body{
    margin-top: 1rem;
}

.react-quiz-container .cardHeaderincorrect{ 
    background-color: #E08882;
}
 .pastQuizes .cardHeaderincorrect{ 
    background-color: #E08882;
}

.react-quiz-container .cardHeadercorrect { 
    background-color:#59B08E;
}

.pastQuizes .cardHeadercorrect { 
    background-color:#59B08E;
}

.react-quiz-container  .result-answer-options{
    margin-left: 3rem;
}

.pastQuizes .result-answer-options{
    margin-left: 3rem;
}

.react-quiz-container .user-answer{
    margin-left: 2rem;
    text-align: center;
}

.react-quiz-container .user-answer{
    margin-left: 2rem;
    text-align: center;
}

.pastQuizes .user-answer{
    margin-left: 2rem;
    text-align: center;
}

.react-quiz-container .quiz-result-filter{
    text-align: center;
}

.pastQuizes .quiz-result-filter{
    text-align: center;
}

.react-quiz-container .quiz-result-filter-form{
    width: 60%;
    display: inline-block;
}

.pastQuizes .quiz-result-filter-form{
    width: 60%;
    display: inline-block;
}


.react-quiz-container .result-answer-wrapper {
    background-color:white;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1); 
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 2rem;
    text-align: center;
    width: 100%;
}

.pastQuizes .result-answer-wrapper {
    background-color:white;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1); 
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 2rem;
    text-align: center;
    width: 100%;
}

.react-quiz-container .question-number{
    text-align: right;
    width: 100%;
    color: #598CAC;
    margin-right: 1rem;
  }

.pastQuizes .question-number{
    text-align: right;
    width: 100%;
    color: #598CAC;
    margin-right: 1rem;
  }

  .react-quiz-container .question-title{
    width: 80%;
    margin-left: 1rem;
    text-align: left;
    color: #598CAC;
    border-bottom-width: 2px ;
    border-bottom-style : solid; 
    border-bottom-color: #598CAC; 
    }

  .pastQuizes .question-title{
    width: 80%;
    margin-left: 1rem;
    text-align: left;
    color: #598CAC;
    border-bottom-width: 2px ;
    border-bottom-style : solid; 
    border-bottom-color: #598CAC; 
    }

.react-quiz-container .question-question{
    text-align: left;
    width: 100%;
    margin-top: 1rem;
    margin-left: 2rem;
    font-size: large ;
    text-decoration: none;
}

.pastQuizes .question-question{
    text-align: left;
    width: 100%;
    margin-top: 1rem;
    margin-left: 2rem;
    font-size: large ;
    text-decoration: none;
}

.react-quiz-container .option-group{
    text-align: left;
    margin-top: 1rem;
    margin-left: 4rem;
    margin-bottom: 2rem;
    width: 100%;
    font-size: large ;
}

.pastQuizes .option-group{
    text-align: left;
    margin-top: 1rem;
    margin-left: 4rem;
    margin-bottom: 2rem;
    width: 100%;
    font-size: large ;
}

.react-quiz-container .option-checkbox{
    text-align: left;
    margin-top: 1rem;
    font-size: large;
    font-weight: 520;
    width: 100%;
    font-size: large ;
}

.pastQuizes .option-checkbox{
    text-align: left;
    margin-top: 1rem;
    font-size: large;
    font-weight: 520;
    width: 100%;
    font-size: large ;
}

.react-quiz-container .result-services-header{
    margin-bottom: 1rem;   
    margin-left: 0;
}

.pastQuizes .result-services-header{
    margin-bottom: 1rem;   
    margin-left: 0;
}

.react-quiz-container .user-answer{
    text-align: left;
    margin-top: 1rem;
    font-size: large;
    font-weight: 520;
    width: 100%;
    font-size: large ;
    margin-left: 0;
}

.pastQuizes .user-answer{
    text-align: left;
    margin-top: 1rem;
    font-size: large;
    font-weight: 520;
    width: 100%;
    font-size: large ;
    margin-left: 0;
}

.react-quiz-container .card-text{
    text-align: left;
    margin: 1rem;
    text-decoration: none;
}

.pastQuizes .card-text{
    text-align: left;
    margin: 1rem;
    text-decoration: none;
}

.react-quiz-container .card-title{
    text-decoration: none;
}

.pastQuizes .card-title{
    text-decoration: none;
}

.react-quiz-container .result-answer-header {
    margin: 0rem;
  }
.pastQuizes .result-answer-header {
  margin: 0rem;
}
.react-quiz-container .result-answer {
    margin-top: 1rem;
    margin-left: 1rem;
    padding-bottom: 1rem;
  }
   
.pastQuizes .result-answer {
    margin-top: 1rem;
    margin-left: 1rem;
    padding-bottom: 1rem;
  }

  .clsBtn{
    margin-top: 1rem;
}


@media  screen and (max-width: 800px) {
    .react-quiz-container .question-question{
        text-align: left;
        width: 100%;
        margin-top: 1rem;
        text-decoration: none;
    }
    
    .pastQuizes .question-question{
        text-align: left;
        width: 100%;
        margin-top: 1rem;
        text-decoration: none;
    }
  }
