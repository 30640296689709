.navbar{
    background-color: #598CAC;
}

.btn{
    background-color: #59B08E;
    border-color:  #f8f8ff;
    color:  #f8f8ff;
    box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1); 
    margin-left: 2rem;
}

.navbar-light .navbar-nav .nav-link {
    color: #F8F8FF;
    margin-left: 1rem;
    font-size: large;
    text-align: center ;
}

.navbar{
    padding-right: 1rem;
}

.form{
    align-items: center;
    text-align: center;
    display: flex;  
}

.a{
    align-items: center;
    text-align: center;
}