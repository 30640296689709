body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8ff;
  height: 100%;
}



@media (min-width: 576px) {  
  h1{
    font-size: 30px;
  }
}
  
@media (min-width: 768px) { 
  h1{
    font-size: 80px;
  }
 }


@media (min-width: 992px) { 

  h1{
    font-size: 80px;
  }
 }

@media (min-width: 1200px) { 

  h1{
    font-size: 100px;
  }

 }


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
